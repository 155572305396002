.side-menu {
    box-sizing: border-box;
    background-color: #3A65EE;
    color: #FFFFFF;
    transition: width 0.3s ease;
    overflow: hidden;
    width: 250px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    padding: 0; /* Adjusted padding */
}

  .side-menu.closed {
    width: 75px;
    padding: 0; /* Adjusted padding in closed state */
}

.menu-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 20px;
    transition: background-color 0.3s ease;
}
  
  .logout-container {
    margin-bottom: 20px; /* Or any value you see fit */
  }
  
  .menu-item:hover, .menu-item.selected {
    background-color: #6A8CFF;
    font-weight: bold;
}

/* Adjust icon and text margin to accommodate the new padding */
.menu-item:hover .icon, .menu-item.selected .icon,
.menu-item:hover .text, .menu-item.selected .text {

}

  .icon {
    margin-right: 10px; /* Add some spacing between the icon and text */
    font-size: 200% !important;;
  }

  
  .text {
    white-space: nowrap;
    margin-left: 20px;
  }

  .top-section {
    display: flex;
    align-items: center; /* Vertically center the content */
    justify-content: space-between;
    height: 75px; /* Set the height of the container */
    padding: 0 20px; /* Adjust as needed */
  }
  
  .top-logo {
    /* margin-top: 20px; Remove this line */
    margin-left: 5%;
    font-size: xx-large;
    font-family: 'Harabara', sans-serif;
    font-weight: normal;
    letter-spacing: 0.02em;
  }

  .toggle-button {
    background-color: white; /* White circle */
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Make the background circle rounded */
    border: none; /* Remove the default border */
    cursor: pointer;
    margin-left: 5px;
  padding: 0;
  }
    
  .toggle-icon {
    color: #3A65EE; /* Set the color of the icon */
  }
  

