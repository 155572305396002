/* Login Page */
.login-container-clinics {
  background: #75B794;
  width: 100vw;        /* Set width to 100% of viewport width */
  overflow-x: hidden;  /* Prevent any horizontal scrolling */
  padding-top: 8%;     /* This pushes the rectangle slightly down from the top */
}

.rectangle {
  /* On desktop, it will take up a maximum of 30% of the screen width, 
     but can become narrower on smaller screens */
  max-width: 30%; 
  min-width: 350px;  /* Consider a minimum width to ensure usability */
  width: 100%;       /* Let it take the full available width up to the max-width */
  margin-left: auto;  
  margin-right: auto;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5vh; /* horizontal padding for the entire rectangle */

}

/* Ensuring that TextField and button container have the same effective width */
.rectangle .mb-4, .login-button-container {
  width: 100%;
  padding: 0 1.5rem; /* Adjust this value for the desired left/right spacing */
  box-sizing: border-box; /* Ensures padding is included in the width */
}

.login-button-container {
  width: 80%;  
  display: flex;
  justify-content: center;
  padding: 0;     /* Reset any paddings */
  margin: 0;      /* Reset any margins */
  margin-left: 5vh;
  margin-right: 5vh;
  margin-bottom: 4vh;
}


/* For mobile view */
@media (max-width: 767.98px) {
  .login-container-clinics {
    padding-top: 30%;     /* This pushes the rectangle slightly down from the top */
  }

  .rectangle {
    /* On mobile devices, width will adapt based on margins */
    max-width: 90%; 
    width: 90%;       /* Let it take the full available width up to the max-width */
    min-width: 0px;  /* Consider a minimum width to ensure usability */
    margin-left: auto;  
    margin-right: auto;
    margin-top: 0px;
  }
}

.login-text {
  font-size: 16px;
  font-family: 'Roboto Rounded', sans-serif;
  font-weight: bold;
  color: black;
}

.login-button-clinics {
  width: 100%;
  padding: .375rem .75rem; /* Default Bootstrap padding for buttons. Adjust if necessary. */
  margin: 0;      /* Reset any margins */
  background: #75B794;
  border: #75B794;
}

.error-message {
  font-family: 'Roboto Rounded', sans-serif;
  font-weight: lighter;
  color: gray;
  font-size: 13px;
}
