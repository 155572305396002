.dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .row {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 30px;
  }
  
  .average-value {
    color: black;
    background-color: white;
    padding: 10px;
    border: 1px solid blue;
  }
  
  .chart-container {
    width: 80%;
    margin: auto;
  }
  
  .pie-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  
  .bar-container {
    margin-left: 100px;
  }
  