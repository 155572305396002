.home-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: url('./images/page1-background.png') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  position: relative;
}

.image-container {
  position: absolute;
  width: 20%;
  max-width: 220px;
  margin-top: 10%;
  margin-left: 30%;
  text-align: center; /* Center-align the images in the container */
}

.image-container img:nth-child(1) {
  height: auto;
  width: 80%; 
  display: block; 
  margin-left: auto; /* Center-align the image */
  margin-right: auto; 
  padding: 10px;
}

.image-container {
  position: absolute;
  width: 20%;
  margin-top: 10%;
  margin-left: 20%;
  text-align: center;
  padding: 1px 0; /* Added small padding */
}

@media (max-width: 767.98px) {
  .image-container {
    width: 30%;
    height: auto;
    margin-top: 30%;
    margin-left: 10%;
  }

  .image-container img:nth-child(1) {
    height: auto; 
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    padding: 10px;
  }

  .image-container img:nth-child(2) {
    height: auto; 
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
  }
}
