/* General Styles */
html,
body,
#root,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Top Bar */
.top-bar {
  height: 75px;
  background-color: #ffffff;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000; /* Keep it above other elements */
  width: 100vw;
}

.top-logo {
  height: 30px;
}

.top-menu-item {
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  font-size: 1rem;
  width: 120px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: font-weight 0.3s, background-color 0.3s;
  overflow: hidden;
  margin-right: 40px;
  border: 1px solid transparent;
  padding: 10px 20px;
}

.top-menu-item:hover,
.top-menu-item.selected {
  background: #3a65ee;
  color: #fff;
  font-weight: bold;
  border-radius: 5px;  /* Adjust this value as needed */
}


@media (min-width: 768px) {
  #navbarNav {
    position: relative; 
    width: 100%;
    display: flex;
    justify-content: flex-end;

  }
}

/* Media Queries */
@media (max-width: 767px) {
  .top-bar {
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  #navbarNav .top-menu-item {
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 5px;
    height: 50px;
    width: 100%;
  }

  #navbarNav {
    position: static; 
    width: 100%;
    margin-top: 18px;
    background-color: #FFF;
    
  }
}

/* Custom Font */
@font-face {
  font-family: "Harabara";
  src: url("./fonts/harabara-mais.oft.otf") format("opentype");
}

/* Copyright Text */
.small-copyright-text {
  position: fixed;
  bottom: 1%;
  left: 0;
  right: 0;
  display: flex;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: lighter;
  font-size: smaller;
  justify-content: center;
}

/* Navbar Toggler */
.navbar-toggler {
  border: none;
  background-color: transparent;
  padding: 0.5rem 1rem;
  margin-right: 12px;
}

.navbar-toggler-icon {
  background-color: transparent;
  height: 28px;
  width: 28px;
  display: block;
  position: relative;
  transition: transform 0.3s ease-in-out;
  background-image: linear-gradient(#000, #000);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 28px 2px;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  background-color: #000;
  height: 2px;
  width: 28px;
  position: absolute;
  left: 0;
  transition: top 0.3s 0.1s ease-in-out, bottom 0.3s 0.1s ease-in-out;
}

.navbar-toggler-icon::before {
  top: 4px;
}

.navbar-toggler-icon::after {
  bottom: 4px;
}

/* Navbar Collapse */
.collapse.show {
  background-color: #ffffff;
}

#navbarNav .top-menu-item {
  margin-left: 0;
  border: none;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
}

/* Add margin to content when navbar is expanded */
.navbar-collapse.show ~ * {
  transform: translateY(calc(100% + 1rem));
  transition: transform 0.3s ease-out;
}

.content-wrapper {
  transition: margin-top 0.3s ease-in-out;  /* Smooth transition */
}
